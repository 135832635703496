<template>
  <div class="meeting_agree_sign">
    <base-sign ref="refBaseSign" @uploadSucc="signUploadSuccess" ></base-sign>
  </div>
</template>

<script>
  export default {
    name: 'MeetingAgreeSign',
    data: () => ({
      detail: {},
      visible: false
    }),
    created () {
      console.log(window.orientation)
      this.checkOrientation ()
    },
    mounted () {
      this.getDetail()
    },
    methods: {
      checkOrientation () {
        if (window.orientation != 90 && window.orientation != -90) {
          this.$notify('建议横屏签字，体验更佳')
        }
      },
      getDetail () {
        const meetingAgreeContent = sessionStorage.getItem('meetingAgreeContent')
        if (meetingAgreeContent) {
          const data = JSON.parse(meetingAgreeContent)
          if (data.agreementContent) {
            this.detail = data
            this.$refs.refBaseSign.init()
          } else {
            this.$router.go(-1)
          }
        } else {
          this.$router.replace('/meeting/agree/login')
        }
      },
      signUploadSuccess ({ url }) {
        const _this = this
        this.$api.meeting.uploadLaborAgreement({
          id: this.detail.id,
          laborAgreementQuestion: this.detail.agreementQuestionContent || '',
          laborAgreementSignUrl: `https://${url}`
        }).then(res => {
          if (!res.code) {
            this.$toast('签署成功')
 
            setTimeout(() => {
              _this.$router.replace('/meeting/agree/list?tabActive=1')
            }, 500);
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.meeting_agree_sign {
  background-color: #FFF;
  min-height: 100%;
}
</style>